/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, TitleMain, Text, Image, Title, Clearfix, Form, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1r035bk --style3 bg--center --full pb--30 pt--30" name={"hotel-uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2533/5c321bef5ade4cef925378c5bf2365b6_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --full --center el--1 mt--08 flex--center" style={{"marginTop":null}} columns={"1"}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":500}}>
              
              <Subtitle className="subtitle-box subtitle-box--style1 subtitle-box--center fs--48 w--900 subtitle-box--invert mt--02" content={"<span style=\"color: rgb(27, 24, 45);\">KOMUNITNÍ DŮM</span>"}>
              </Subtitle>

              <TitleMain className="title-box title-box--style3 title-box--center fs--72 mt--0" style={{"marginTop":null,"paddingBottom":23.5859375}} content={"<span style=\"color: rgb(29, 27, 36);\">BÍLOVEC</span>"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--right pb--30 pt--30" name={"52gpeb16njp"} style={{"backgroundColor":"rgba(245, 166, 35, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box title-box--style1 ff--1 fs--30" content={"Hledáte-li odpovědi na níže uvedené otázky"}>
              </TitleMain>

              <Subtitle className="subtitle-box subtitle-box--style1 subtitle-box--center ff--1 fs--14 lh--12" content={"<span style=\"color: rgb(0, 0, 0);\">1)\tChcete vyřešit Vaše bydlení?  Současné je drahé, velké, daleko od občanské vybavenosti (obchod, lékař, …) <br></span><span style=\"color: rgb(139, 87, 42);\"><span style=\"color: rgb(0, 0, 0);\"><br>2)\tNechcete být osamělý/á, chcete plnohodnotně žít v bytovém domě spolu s přáteli v podobném věku?\n</span></span><span style=\"color: rgb(0, 0, 0);\"><br><br></span><span style=\"color: rgb(139, 87, 42);\"><span style=\"color: rgb(0, 0, 0);\">3)\tChcete mít jistotu regulovaného nájmu Ministerstvem pro místní rozvoj?</span></span><span style=\"color: rgb(0, 0, 0);\"><br><br></span><span style=\"color: rgb(139, 87, 42);\"><span style=\"color: rgb(0, 0, 0);\">4)\tJe Vám více než 60 let, v případě manželů jeden je starší 60ti let?\n</span></span><span style=\"color: rgb(0, 0, 0);\"><br><br></span><span style=\"color: rgb(139, 87, 42);\"><span style=\"color: rgb(0, 0, 0);\">5)\tVáš měsíční příjem nepřevyšuje průměrnou měsíční mzdu? </span></span><span style=\"color: rgb(139, 87, 42);\"><span style=\"color: rgb(0, 0, 0);\"><br></span></span><span style=\"color: rgb(208, 2, 27);\">MY ZNÁME ODPOVĚĎ A MÁME PRO VÁS ŘEŠENÍ.&nbsp;</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"uicv28nct8"} layout={"l16"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box title-box--style2 fs--24" style={{"maxWidth":516,"backgroundColor":"rgba(210,180,60,1)"}} content={"<span style=\"color: rgb(5, 5, 5); background-color: rgb(210, 180, 60);\">KOMUNITNÍ BYDLENÍ PRO SAMOSTATNÉ SENIORY \n\nspolufinancované \n\nMinisterstvem pro místní rozvoj</span>"}>
              </TitleMain>

              <Text className="text-box fs--20" content={"<span style=\"font-weight: normal; color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);\">Cílem podprogramu Podporované bydlení, je vznik podporovaných bytů na území České republiky sloužících k poskytování sociálního bydlení pro osoby, které mají ztížený přístup k bydlení v důsledku zvláštních potřeb vyplývajících z jejich nepříznivé sociální situace – věk, zdravotní stav nebo sociální okolnosti jejich života.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/f907b99b6a97468ab708e922b13db409_s=350x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2533/f907b99b6a97468ab708e922b13db409_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box fs--16 w--300 mt--06" content={"<span style=\"font-weight: bold; color: rgb(18, 18, 18);\">Odbor politiky bydlení, Staroměstské nám. 6, 110 15 Praha 1</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"onas"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style1 ff--1" content={"PRO KOHO TADY JSME"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: rgb(0, 0, 0);\">Komunitní dům pro seniory je otevřen seniorům, kteří chtějí žít samostatně, bezpečně, pohodlně, v blízkosti lidí stejného věku nebo rodiny, ale ve svém soukromí. Seniorům, kteří chtějí využívat svůj volný čas plnohodnotným a smysluplným způsobem, který bude dělat radost hlavně jim a jejich blízkým, což má pozitivní dopad na jejich dobré fyzické i duševní zdraví. Vytvořená společenská místnost v Komunitním domě, skýtá i zázemí pro všechny, kteří z individuálních příčin nemohou sdílet společensky laděný venkovní život – posezení s přáteli v v cukrárně, návštěvy knihoven, kulturních zařízení apod. </span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style1 ff--1" content={"PROČ JSME LEPŠÍ VOLBOU"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: rgb(0, 0, 0);\">Nabízíme domácí prostředí Komunitního domu seniorů se samostatným bydlením, zaručující soukromí vlastního bytu – 1+kk. Vybavení Komunitního domu a jeho zázemí – společenská místnost s kuchyní a knihovnou. Život v přátelské komunitě. Aktivizační a společenské aktivity dle podnětů seniorů. V případě, že se zdravotní stav nájemnice/nájemníka zhorší, je Komunitní dům pro seniory schopen zajistit terénní pečovatelskou službu či domácí péči, se kterými máme uzavřenou smlouvu o spolupráci a chránit tak nájemnici/nájemníka před dalším stěhováním. Otevřený a individuální přístup, Domácí mazlíček není problém.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"galerie"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l12"}>
        </Column>


        <Column className="pb--30 pt--30" name={"vpsf70v4axk"} style={{"backgroundColor":"rgba(24, 24, 27, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/403dda3b74fc429cb3247a43fb38031d_s=660x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/403dda3b74fc429cb3247a43fb38031d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/403dda3b74fc429cb3247a43fb38031d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/403dda3b74fc429cb3247a43fb38031d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Projektová vizualizace 01"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/59e19e4759154e5f9d7456082152378d_s=660x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/59e19e4759154e5f9d7456082152378d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/59e19e4759154e5f9d7456082152378d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/59e19e4759154e5f9d7456082152378d_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Projektová vizualizace 02"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/ee055de3cd2d45dc9390208e32b4dc74_s=660x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/ee055de3cd2d45dc9390208e32b4dc74_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/ee055de3cd2d45dc9390208e32b4dc74_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/ee055de3cd2d45dc9390208e32b4dc74_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: rgb(155, 155, 155);\">Projektová vizualizace 03</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

          </ColumnWrap>

          <Clearfix >
          </Clearfix>

        </Column>


        <Column className="pb--30 pt--30" name={"2kk2d35q9y5"} style={{"backgroundColor":"rgba(245, 166, 35, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/8f698d88620c41d6ac055c31bce6bca4_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/8f698d88620c41d6ac055c31bce6bca4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/8f698d88620c41d6ac055c31bce6bca4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/8f698d88620c41d6ac055c31bce6bca4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/8f698d88620c41d6ac055c31bce6bca4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/8f698d88620c41d6ac055c31bce6bca4_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: rgb(7, 7, 7);\">Výstavba 1</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/92aadeb068754f4987ddc31691b1ad23_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/92aadeb068754f4987ddc31691b1ad23_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/92aadeb068754f4987ddc31691b1ad23_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/92aadeb068754f4987ddc31691b1ad23_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/92aadeb068754f4987ddc31691b1ad23_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/92aadeb068754f4987ddc31691b1ad23_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: rgb(0, 0, 0);\">Výstavba 2</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/c5fda8582aa941a18be44a9b1b5ca00b_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/c5fda8582aa941a18be44a9b1b5ca00b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/c5fda8582aa941a18be44a9b1b5ca00b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/c5fda8582aa941a18be44a9b1b5ca00b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/c5fda8582aa941a18be44a9b1b5ca00b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/c5fda8582aa941a18be44a9b1b5ca00b_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"<span style=\"color: rgb(0, 0, 0);\">Výstavba 3</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

          </ColumnWrap>

          <Clearfix >
          </Clearfix>

        </Column>


        <Column className="pb--30 pt--30" name={"gah5zdtxzf"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/adf97fe0d427440fad080a791313bd17_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/adf97fe0d427440fad080a791313bd17_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/adf97fe0d427440fad080a791313bd17_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/adf97fe0d427440fad080a791313bd17_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/adf97fe0d427440fad080a791313bd17_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/adf97fe0d427440fad080a791313bd17_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Současnost 1"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/a8f3311e869f4ac6b0835dd1aa58cf1f_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/a8f3311e869f4ac6b0835dd1aa58cf1f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/a8f3311e869f4ac6b0835dd1aa58cf1f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/a8f3311e869f4ac6b0835dd1aa58cf1f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/a8f3311e869f4ac6b0835dd1aa58cf1f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/a8f3311e869f4ac6b0835dd1aa58cf1f_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Současnost 2"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/0d2e50de052d4c659ab3423a31563d9e_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/0d2e50de052d4c659ab3423a31563d9e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/0d2e50de052d4c659ab3423a31563d9e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/0d2e50de052d4c659ab3423a31563d9e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/0d2e50de052d4c659ab3423a31563d9e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/0d2e50de052d4c659ab3423a31563d9e_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Současnost 3"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"lu189hd0slj"} style={{"backgroundColor":"rgba(18,18,18,1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/16c4e8adc3864d209f3801f0d6a20655_s=660x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/16c4e8adc3864d209f3801f0d6a20655_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2533/16c4e8adc3864d209f3801f0d6a20655_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2533/16c4e8adc3864d209f3801f0d6a20655_s=860x_.jpeg 860w"} position={{"x":"-0.2994011976047893%","y":"0.5988023952095808%"}} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Ukázka byt 01"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/f5bb414d440c4561a7f6f8df57cc93f9_s=660x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/f5bb414d440c4561a7f6f8df57cc93f9_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2533/f5bb414d440c4561a7f6f8df57cc93f9_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2533/f5bb414d440c4561a7f6f8df57cc93f9_s=860x_.jpeg 860w"} position={{"x":"-2.0958083832335332%","y":"-3.3306690738754696e-16%"}} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Ukázka byt 02"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/4aea93ae326748518991ed8a0b098b8b_s=660x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/4aea93ae326748518991ed8a0b098b8b_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2533/4aea93ae326748518991ed8a0b098b8b_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2533/4aea93ae326748518991ed8a0b098b8b_s=860x_.jpeg 860w"} position={{"x":"-1.1976047904191618%","y":"1.7964071856287422%"}} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Ukázka byt 03"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"r5g09dwx4me"} style={{"backgroundColor":"rgba(245, 166, 35, 1)"}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/33b81ca1e85944acacaca4fce6e4c883_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/33b81ca1e85944acacaca4fce6e4c883_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/33b81ca1e85944acacaca4fce6e4c883_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/33b81ca1e85944acacaca4fce6e4c883_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/33b81ca1e85944acacaca4fce6e4c883_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/33b81ca1e85944acacaca4fce6e4c883_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Komunitní místnost 01"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/4df8d1a306ba488ba17d4b98b174d7d5_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/4df8d1a306ba488ba17d4b98b174d7d5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/4df8d1a306ba488ba17d4b98b174d7d5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/4df8d1a306ba488ba17d4b98b174d7d5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/4df8d1a306ba488ba17d4b98b174d7d5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/4df8d1a306ba488ba17d4b98b174d7d5_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Komunitní místnost 02"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2533/bff32085b7494fb284bb9c3a669415a6_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2533/bff32085b7494fb284bb9c3a669415a6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2533/bff32085b7494fb284bb9c3a669415a6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2533/bff32085b7494fb284bb9c3a669415a6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2533/bff32085b7494fb284bb9c3a669415a6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2533/bff32085b7494fb284bb9c3a669415a6_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center fs--16 w--300 mt--06" content={"Komunitní místnost 03"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"h9pvzawmjap"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1" content={"CÍLOVÁ SKUPINA"}>
              </Title>

              <Subtitle className="subtitle-box" content={"podmínky pro možnost bydlení v Komunitním domě"}>
              </Subtitle>

              <Text className="text-box text-box--left fs--18 w--900" content={"<span style=\"color: rgb(0, 0, 0);\">VĚK:<span style=\"font-weight: normal;\"> Osoby ve věku 60 let a více (60+). V případě, že se jedná o manžele, partnery, stačí, když jeden z partnerů bude splňovat tuto podmínku. </span><br><br>PŘÍJEM A VLASTNICTVÍ:<span style=\"font-weight: normal;\"> Průměrný čistý měsíční příjem v období 12 kalendářních měsíců před uzavřením nájemní smlouvy NESMÍ přesáhnout 1 násobek průměrné měsíční mzdy v ČR, v případě 1 členné domácnosti nebo 1,2 násobek v případě 2 členné domácnosti. Nový nájemce ani další členové domácnosti, kteří mají v podporovaném bytě bydlet, nesmí mít ke dni podpisu smlouvy ve vlastnictví ani podílovém spoluvlastnictví bytový dům, rodinný dům nebo byt nebo družstevní podíl.</span><br><br>VÝŠE NÁJMU A S TÍM SPOJENÉ:<span style=\"font-weight: normal;\">&nbsp;Limity nájemného vyhlašuje a reguluje MMR a to dle míry inflace daného roku. <br>V nájmu nejsou započteny zálohy na energie, vodu a další poplatky spojené s nájmem bytu. Dále platba za rozhlas + TV, popelnice, internet atd.&nbsp;</span><br><span style=\"font-family: Muli, sans-serif; font-size: 18px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; letter-spacing: -0.54px;\"><br>Nájemní smlouva</span><span style=\"font-family: Muli, sans-serif; font-size: 18px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.54px;\"> se uzavírá na dva roky. Pokud nájemce splní všechny podmínky pro podporované bydlení.</span><br><br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"h9pvzawmjap"} style={{"backgroundColor":"rgba(18,18,18,1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--1" content={"<span style=\"color: rgb(255, 255, 255);\">JAK KOMUNITNÍ DŮM VYPADÁ</span>&nbsp;"}>
              </Title>

              <Text className="text-box text-box--left fs--18 w--900" content={"<span style=\"font-weight: normal; color: rgb(255, 255, 255);\">V prvním a druhém nadzemním podlaží jsou umístěny bytové jednotky tvořené pokojem s kuchyňským koutem, předsíní, hygienickým zařízením a komorou. Vnitřní dispozice objektu je koncipována s ohledem na sníženou schopnost mobility jeho obyvatel. V budově je proto také osobní výtah. V přízemí se také nachází klubovna umožňující pořádání společenských setkání obyvatel domu, a různých kulturních či výukových programů, případně individuální využití – setkání s rodinou, posezení s přáteli apod.&nbsp;<br><br>V bezprostřední blízkosti domu bude vybudováno parkoviště. Poloha Komunitního domu je výhodná z hlediska docházkových vzdáleností, je pouhých 5 min od centra města Bílovec, cca. 15-20min. zdravotní středisko, nemocnice, lékárna, MHD a samozřejmě veškerá občanská vybavenost.&nbsp;<br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"we9ieb4nfb"} style={{"backgroundColor":"rgba(245, 166, 35, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--2" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box title-box--style1 ff--1 fs--26 w--300" content={"<span style=\"font-weight: bold;\">PŘÍKLAD USPOŘÁDÁNÍ BYTU<br><br>20 bytových jednotek<br>velikosti <br>od 29,6m2 do 44,55m2</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2533/ed56f41e99fb41f48b6320557ab4f5c0_s=350x_.png"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2533/ed56f41e99fb41f48b6320557ab4f5c0_s=350x_.png 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"rezervace"} style={{"backgroundColor":"rgba(242, 242, 242, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" content={"KONTAKTNÍ FORMULÁŘ"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Form action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"ODESLAT","type":"submit"}]} layout={"l3"}>
              </Form>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"mapa"}>
        </Fullmap>


        <Fullmap className="--style2" name={"7kwb7xkuqqs"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"17"} place={"Zahradní 637/21"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--40 pt--40" name={"ip1e6z0n89c"} style={{"backgroundColor":"var(--color-main-bg-2)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--right">
              
              <Title className="title-box title-box--style1 title-box--center ff--1 fs--26 title-box--invert" content={"KOMUNITNÍ DŮM BÍLOVEC"}>
              </Title>

              <Text className="text-box text-box--center fs--16 text-box--invert mt--10" style={{"maxWidth":400}} content={"Komunitní dům senirou"}>
              </Text>

              <Text className="text-box text-box--center fs--16 text-box--invert" content={"Navštivte také náší stránku na&nbsp;<a href=\"https://www.facebook.com/komunitnidum/?ref=bookmarks\">facebook.com</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"Telefonní kontakt:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"+420 776 370 390, +420 596 136 340"}>
              </Text>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"E-mail:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"komunitnidum@seznam.cz"}>
              </Text>

              <Subtitle className="subtitle-box fs--16 subtitle-box--invert" content={"Adresa:"}>
              </Subtitle>

              <Text className="text-box fs--18 w--600 text-box--invert mt--0" content={"KOMUNITNÍ DŮM s.r.o.<br>Zahradní 637/21<br>743 01 Bílovec<br>Česká republika<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"92eqcyraq9"} style={{"backgroundColor":"rgba(18,18,18,1)"}}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--1" content={"<span style=\"color: rgb(155, 155, 155);\">Domovní řád</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Soubor -&nbsp;&nbsp;<a href=\"https://cdn.swbpg.com/d/2533/kdum-domovni-rad-pdf\" target=\"_blank\">PDF</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--1" content={"<span style=\"color: rgb(155, 155, 155);\">GDPR</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Soubor - <a href=\"https://cdn.swbpg.com/d/2533/kdum-gdpr-pdf\" target=\"_blank\">PDF</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--1" content={"<span style=\"color: rgb(155, 155, 155);\">Požární řád</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Soubor - <a href=\"https://cdn.swbpg.com/d/2533/kdum-pozarni-rad-pdf\" target=\"_blank\">PDF</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}